@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Poppins:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.app {
  margin: auto;
}

img {
  filter: brightness(90%);
}

.item {
  transition: all 0.4s ease-out;        
}  

.item:hover {
  transform: scale(1.05);
}

.item:hover::after {
  content: "";
  position: absolute;        
  width: 100%;    
  height: 100%;
  top: 0;                
}

.centered-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1, h2, h3, h4, h5, h6, p, a {
  transition: color 0.5s ease;
}

@media (max-width: 768px) {
  /* Add styles for mobile devices here */

  .auth-container {
    padding: 0.5rem;
  }

  .auth-form {
    padding: 1rem;
  }

  .auth-form input {
    margin-bottom: 0.5rem;
  }

  .auth-form button {
    margin-top: 0.5rem;
  }
}

.shop-now-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-search-input .MuiFilledInput-input.Mui-focused {
  color: #ffffff;
}

/* Apply styles to all scrollbars */
::-webkit-scrollbar {
  width: 5px;
}

/* Apply styles to the scrollbar's thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: rgba(128, 125, 125, 0.5);
  border-radius: 10px;
}

/* Apply styles to the scrollbar's track (the background) */
::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color to transparent */
  border-radius: 10px;
}